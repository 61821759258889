
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1i0s3_12tgd_97",
  "header": "awsui_header_1i0s3_12tgd_132",
  "content": "awsui_content_1i0s3_12tgd_136",
  "error": "awsui_error_1i0s3_12tgd_140",
  "footer": "awsui_footer_1i0s3_12tgd_144",
  "actions-section": "awsui_actions-section_1i0s3_12tgd_148",
  "secondary-actions": "awsui_secondary-actions_1i0s3_12tgd_157",
  "actions": "awsui_actions_1i0s3_12tgd_148"
};
  