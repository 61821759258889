
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_h11ix_ayzd6_101",
  "icon-flex-height": "awsui_icon-flex-height_h11ix_ayzd6_109",
  "size-small": "awsui_size-small_h11ix_ayzd6_134",
  "size-small-mapped-height": "awsui_size-small-mapped-height_h11ix_ayzd6_138",
  "size-normal": "awsui_size-normal_h11ix_ayzd6_153",
  "size-normal-mapped-height": "awsui_size-normal-mapped-height_h11ix_ayzd6_157",
  "size-medium": "awsui_size-medium_h11ix_ayzd6_172",
  "size-medium-mapped-height": "awsui_size-medium-mapped-height_h11ix_ayzd6_176",
  "size-big": "awsui_size-big_h11ix_ayzd6_191",
  "size-big-mapped-height": "awsui_size-big-mapped-height_h11ix_ayzd6_195",
  "size-large": "awsui_size-large_h11ix_ayzd6_210",
  "size-large-mapped-height": "awsui_size-large-mapped-height_h11ix_ayzd6_214",
  "variant-normal": "awsui_variant-normal_h11ix_ayzd6_229",
  "variant-disabled": "awsui_variant-disabled_h11ix_ayzd6_232",
  "variant-inverted": "awsui_variant-inverted_h11ix_ayzd6_235",
  "variant-subtle": "awsui_variant-subtle_h11ix_ayzd6_238",
  "variant-warning": "awsui_variant-warning_h11ix_ayzd6_241",
  "variant-error": "awsui_variant-error_h11ix_ayzd6_244",
  "variant-success": "awsui_variant-success_h11ix_ayzd6_247",
  "variant-link": "awsui_variant-link_h11ix_ayzd6_250",
  "name-angle-left-double": "awsui_name-angle-left-double_h11ix_ayzd6_253",
  "name-angle-left": "awsui_name-angle-left_h11ix_ayzd6_253",
  "name-angle-right-double": "awsui_name-angle-right-double_h11ix_ayzd6_255",
  "name-angle-right": "awsui_name-angle-right_h11ix_ayzd6_255",
  "name-arrow-left": "awsui_name-arrow-left_h11ix_ayzd6_257",
  "name-caret-left-filled": "awsui_name-caret-left-filled_h11ix_ayzd6_258",
  "name-caret-right-filled": "awsui_name-caret-right-filled_h11ix_ayzd6_259",
  "name-audio-full": "awsui_name-audio-full_h11ix_ayzd6_260",
  "name-audio-half": "awsui_name-audio-half_h11ix_ayzd6_261",
  "name-audio-off": "awsui_name-audio-off_h11ix_ayzd6_262",
  "name-external": "awsui_name-external_h11ix_ayzd6_263",
  "name-redo": "awsui_name-redo_h11ix_ayzd6_264",
  "name-resize-area": "awsui_name-resize-area_h11ix_ayzd6_265",
  "name-send": "awsui_name-send_h11ix_ayzd6_266",
  "name-shrink": "awsui_name-shrink_h11ix_ayzd6_267",
  "name-undo": "awsui_name-undo_h11ix_ayzd6_268",
  "name-view-vertical": "awsui_name-view-vertical_h11ix_ayzd6_269",
  "badge": "awsui_badge_h11ix_ayzd6_292"
};
  