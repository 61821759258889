
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_4yi2u_1w171_97",
  "outline": "awsui_outline_4yi2u_1w171_133",
  "toggle-control": "awsui_toggle-control_4yi2u_1w171_155",
  "toggle-control-checked": "awsui_toggle-control-checked_4yi2u_1w171_168",
  "toggle-control-disabled": "awsui_toggle-control-disabled_4yi2u_1w171_171",
  "toggle-control-readonly": "awsui_toggle-control-readonly_4yi2u_1w171_177",
  "toggle-handle": "awsui_toggle-handle_4yi2u_1w171_181",
  "toggle-handle-checked": "awsui_toggle-handle-checked_4yi2u_1w171_196",
  "toggle-handle-disabled": "awsui_toggle-handle-disabled_4yi2u_1w171_203",
  "toggle-handle-readonly": "awsui_toggle-handle-readonly_4yi2u_1w171_207"
};
  