
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_145pa_6roki_97",
  "focus-lock": "awsui_focus-lock_145pa_6roki_132",
  "calendar": "awsui_calendar_145pa_6roki_136",
  "date-picker-container": "awsui_date-picker-container_145pa_6roki_152",
  "date-picker-trigger": "awsui_date-picker-trigger_145pa_6roki_157",
  "date-picker-input": "awsui_date-picker-input_145pa_6roki_161",
  "open-calendar-button": "awsui_open-calendar-button_145pa_6roki_166"
};
  