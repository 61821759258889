
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_1kz54_194",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_1kz54_202",
  "pagination-button": "awsui_pagination-button_14rmt_1kz54_219",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_1kz54_226",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_1kz54_229",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_1kz54_233",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_1kz54_236",
  "tabs-tab": "awsui_tabs-tab_14rmt_1kz54_241",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_1kz54_250",
  "tabs-tab-dismiss": "awsui_tabs-tab-dismiss_14rmt_1kz54_261",
  "tabs-tab-action": "awsui_tabs-tab-action_14rmt_1kz54_262",
  "tabs-tab-header-container": "awsui_tabs-tab-header-container_14rmt_1kz54_266",
  "refresh": "awsui_refresh_14rmt_1kz54_281",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_1kz54_285",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_1kz54_327",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_1kz54_403",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_1kz54_410",
  "tabs-tab-focusable": "awsui_tabs-tab-focusable_14rmt_1kz54_414",
  "root": "awsui_root_14rmt_1kz54_418",
  "tabs": "awsui_tabs_14rmt_1kz54_194",
  "tabs-content": "awsui_tabs-content_14rmt_1kz54_459",
  "fit-height": "awsui_fit-height_14rmt_1kz54_463",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_1kz54_469",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_1kz54_483",
  "with-paddings": "awsui_with-paddings_14rmt_1kz54_483",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_1kz54_494",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_14rmt_1kz54_505",
  "tabs-tab-focused": "awsui_tabs-tab-focused_14rmt_1kz54_509"
};
  