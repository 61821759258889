
    import './styles.scoped.css';
    export default {
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_1878d_97",
  "trigger": "awsui_trigger_lpshu_1878d_97",
  "selected": "awsui_selected_lpshu_1878d_192",
  "badge": "awsui_badge_lpshu_1878d_220",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_1878d_224",
  "dot": "awsui_dot_lpshu_1878d_232"
};
  