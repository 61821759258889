
    import './styles.scoped.css';
    export default {
  "item-element": "awsui_item-element_93a1u_16wwy_97",
  "disabled": "awsui_disabled_93a1u_16wwy_109",
  "show-divider": "awsui_show-divider_93a1u_16wwy_116",
  "highlighted": "awsui_highlighted_93a1u_16wwy_119",
  "is-focused": "awsui_is-focused_93a1u_16wwy_134",
  "menu-item": "awsui_menu-item_93a1u_16wwy_139",
  "has-category-header": "awsui_has-category-header_93a1u_16wwy_154",
  "item-tooltip-wrapper": "awsui_item-tooltip-wrapper_93a1u_16wwy_154",
  "has-checkmark": "awsui_has-checkmark_93a1u_16wwy_154",
  "icon": "awsui_icon_93a1u_16wwy_158",
  "checkmark": "awsui_checkmark_93a1u_16wwy_162",
  "external-icon": "awsui_external-icon_93a1u_16wwy_169"
};
  