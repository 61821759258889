
    import './styles.scoped.css';
    export default {
  "option": "awsui_option_1p2cx_1lo2r_101",
  "disabled": "awsui_disabled_1p2cx_1lo2r_141",
  "parent": "awsui_parent_1p2cx_1lo2r_144",
  "highlighted": "awsui_highlighted_1p2cx_1lo2r_147",
  "content": "awsui_content_1p2cx_1lo2r_151",
  "label-content": "awsui_label-content_1p2cx_1lo2r_158",
  "label": "awsui_label_1p2cx_1lo2r_158",
  "tag": "awsui_tag_1p2cx_1lo2r_165",
  "label-tag": "awsui_label-tag_1p2cx_1lo2r_166",
  "label-prefix": "awsui_label-prefix_1p2cx_1lo2r_176",
  "tags": "awsui_tags_1p2cx_1lo2r_189",
  "description": "awsui_description_1p2cx_1lo2r_190",
  "selected": "awsui_selected_1p2cx_1lo2r_201",
  "icon": "awsui_icon_1p2cx_1lo2r_215",
  "icon-size-big": "awsui_icon-size-big_1p2cx_1lo2r_222",
  "filtering-match-highlight": "awsui_filtering-match-highlight_1p2cx_1lo2r_226",
  "trigger-variant": "awsui_trigger-variant_1p2cx_1lo2r_241"
};
  