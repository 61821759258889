
    import './styles.scoped.css';
    export default {
  "toggle": "awsui_toggle_1uo6m_1exhv_101",
  "drawer-triggers": "awsui_drawer-triggers_1uo6m_1exhv_107",
  "drawer": "awsui_drawer_1uo6m_1exhv_107",
  "drawer-mobile": "awsui_drawer-mobile_1uo6m_1exhv_117",
  "drawer-closed": "awsui_drawer-closed_1uo6m_1exhv_120",
  "drawer-content": "awsui_drawer-content_1uo6m_1exhv_127",
  "drawer-content-clickable": "awsui_drawer-content-clickable_1uo6m_1exhv_139",
  "drawer-resize-content": "awsui_drawer-resize-content_1uo6m_1exhv_149",
  "drawer-triggers-wrapper": "awsui_drawer-triggers-wrapper_1uo6m_1exhv_155",
  "drawer-trigger": "awsui_drawer-trigger_1uo6m_1exhv_107",
  "drawer-trigger-active": "awsui_drawer-trigger-active_1uo6m_1exhv_174",
  "resize-handle-wrapper": "awsui_resize-handle-wrapper_1uo6m_1exhv_182",
  "hide": "awsui_hide_1uo6m_1exhv_192"
};
  