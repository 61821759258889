
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18582_jftqg_97",
  "child": "awsui_child_18582_jftqg_101",
  "horizontal": "awsui_horizontal_18582_jftqg_112",
  "horizontal-xxxs": "awsui_horizontal-xxxs_18582_jftqg_116",
  "horizontal-xxs": "awsui_horizontal-xxs_18582_jftqg_119",
  "horizontal-xs": "awsui_horizontal-xs_18582_jftqg_122",
  "horizontal-s": "awsui_horizontal-s_18582_jftqg_125",
  "horizontal-m": "awsui_horizontal-m_18582_jftqg_128",
  "horizontal-l": "awsui_horizontal-l_18582_jftqg_131",
  "horizontal-xl": "awsui_horizontal-xl_18582_jftqg_134",
  "horizontal-xxl": "awsui_horizontal-xxl_18582_jftqg_137",
  "vertical": "awsui_vertical_18582_jftqg_144",
  "vertical-xxxs": "awsui_vertical-xxxs_18582_jftqg_147",
  "vertical-xxs": "awsui_vertical-xxs_18582_jftqg_150",
  "vertical-xs": "awsui_vertical-xs_18582_jftqg_153",
  "vertical-s": "awsui_vertical-s_18582_jftqg_156",
  "vertical-m": "awsui_vertical-m_18582_jftqg_159",
  "vertical-l": "awsui_vertical-l_18582_jftqg_162",
  "vertical-xl": "awsui_vertical-xl_18582_jftqg_165",
  "vertical-xxl": "awsui_vertical-xxl_18582_jftqg_168",
  "align-center": "awsui_align-center_18582_jftqg_172",
  "align-start": "awsui_align-start_18582_jftqg_176",
  "align-end": "awsui_align-end_18582_jftqg_180"
};
  