
    import './styles.scoped.css';
    export default {
  "header-cell": "awsui_header-cell_1spae_geicw_97",
  "header-cell-fake-focus": "awsui_header-cell-fake-focus_1spae_geicw_131",
  "header-cell-sticky": "awsui_header-cell-sticky_1spae_geicw_152",
  "header-cell-stuck": "awsui_header-cell-stuck_1spae_geicw_155",
  "header-cell-variant-full-page": "awsui_header-cell-variant-full-page_1spae_geicw_155",
  "header-cell-hidden": "awsui_header-cell-hidden_1spae_geicw_161",
  "header-cell-sortable": "awsui_header-cell-sortable_1spae_geicw_164",
  "sticky-cell": "awsui_sticky-cell_1spae_geicw_167",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_1spae_geicw_175",
  "has-selection": "awsui_has-selection_1spae_geicw_175",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_1spae_geicw_178",
  "resize-divider": "awsui_resize-divider_1spae_geicw_183",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_1spae_geicw_190",
  "sorting-icon": "awsui_sorting-icon_1spae_geicw_210",
  "edit-icon": "awsui_edit-icon_1spae_geicw_218",
  "header-cell-content": "awsui_header-cell-content_1spae_geicw_224",
  "header-cell-content-expandable": "awsui_header-cell-content-expandable_1spae_geicw_230",
  "header-cell-disabled": "awsui_header-cell-disabled_1spae_geicw_282",
  "header-cell-sorted": "awsui_header-cell-sorted_1spae_geicw_282",
  "header-cell-text": "awsui_header-cell-text_1spae_geicw_296",
  "header-cell-text-wrap": "awsui_header-cell-text-wrap_1spae_geicw_300",
  "header-cell-ascending": "awsui_header-cell-ascending_1spae_geicw_306",
  "header-cell-descending": "awsui_header-cell-descending_1spae_geicw_307",
  "is-visual-refresh": "awsui_is-visual-refresh_1spae_geicw_317",
  "has-striped-rows": "awsui_has-striped-rows_1spae_geicw_421",
  "sticky-cell-pad-inline-start": "awsui_sticky-cell-pad-inline-start_1spae_geicw_427"
};
  