// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// expose version info, so it can be checked using the browser devtools
export function initAwsUiVersions(source, packageVersion) {
    if (typeof window !== 'undefined') {
        if (!window.awsuiVersions) {
            window.awsuiVersions = {};
        }
        if (!window.awsuiVersions[source]) {
            window.awsuiVersions[source] = [];
        }
        window.awsuiVersions[source].push(packageVersion);
    }
}
