
    import './styles.scoped.css';
    export default {
  "universal-toolbar": "awsui_universal-toolbar_1kzri_18urq_97",
  "disable-body-scroll": "awsui_disable-body-scroll_1kzri_18urq_118",
  "toolbar-hidden": "awsui_toolbar-hidden_1kzri_18urq_121",
  "toolbar-container": "awsui_toolbar-container_1kzri_18urq_124",
  "universal-toolbar-nav": "awsui_universal-toolbar-nav_1kzri_18urq_133",
  "universal-toolbar-breadcrumbs": "awsui_universal-toolbar-breadcrumbs_1kzri_18urq_137",
  "universal-toolbar-drawers": "awsui_universal-toolbar-drawers_1kzri_18urq_142",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_1kzri_18urq_149",
  "drawers-trigger-content": "awsui_drawers-trigger-content_1kzri_18urq_193",
  "has-multiple-triggers": "awsui_has-multiple-triggers_1kzri_18urq_200",
  "has-open-drawer": "awsui_has-open-drawer_1kzri_18urq_200",
  "drawers-trigger": "awsui_drawers-trigger_1kzri_18urq_193"
};
  