
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_1vekq_101",
  "fit-height": "awsui_fit-height_14iqq_1vekq_137",
  "with-side-media": "awsui_with-side-media_14iqq_1vekq_142",
  "variant-default": "awsui_variant-default_14iqq_1vekq_145",
  "variant-stacked": "awsui_variant-stacked_14iqq_1vekq_145",
  "refresh": "awsui_refresh_14iqq_1vekq_153",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_1vekq_203",
  "with-top-media": "awsui_with-top-media_14iqq_1vekq_215",
  "content-wrapper": "awsui_content-wrapper_14iqq_1vekq_220",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_1vekq_225",
  "media": "awsui_media_14iqq_1vekq_232",
  "media-top": "awsui_media-top_14iqq_1vekq_250",
  "media-side": "awsui_media-side_14iqq_1vekq_255",
  "header": "awsui_header_14iqq_1vekq_261",
  "header-full-page": "awsui_header-full-page_14iqq_1vekq_266",
  "header-with-media": "awsui_header-with-media_14iqq_1vekq_269",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_1vekq_275",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_1vekq_279",
  "header-stuck": "awsui_header-stuck_14iqq_1vekq_285",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_1vekq_295",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_1vekq_298",
  "with-paddings": "awsui_with-paddings_14iqq_1vekq_304",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_1vekq_313",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_1vekq_371",
  "header-cover": "awsui_header-cover_14iqq_1vekq_374",
  "content": "awsui_content_14iqq_1vekq_220",
  "content-fit-height": "awsui_content-fit-height_14iqq_1vekq_402",
  "content-with-media": "awsui_content-with-media_14iqq_1vekq_412",
  "footer": "awsui_footer_14iqq_1vekq_416",
  "with-divider": "awsui_with-divider_14iqq_1vekq_420"
};
  