
    import './styles.scoped.css';
    export default {
  "block-body-scroll": "awsui_block-body-scroll_19d8l_142in_101",
  "mobile-bar": "awsui_mobile-bar_19d8l_142in_105",
  "mobile-bar-breadcrumbs": "awsui_mobile-bar-breadcrumbs_19d8l_142in_118",
  "mobile-toggle": "awsui_mobile-toggle_19d8l_142in_125",
  "mobile-toggle-type-navigation": "awsui_mobile-toggle-type-navigation_19d8l_142in_134",
  "mobile-toggle-type-tools": "awsui_mobile-toggle-type-tools_19d8l_142in_137",
  "mobile-toggle-type-drawer": "awsui_mobile-toggle-type-drawer_19d8l_142in_137",
  "drawers-container": "awsui_drawers-container_19d8l_142in_144"
};
  