
    import './styles.scoped.css';
    export default {
  "placeholder": "awsui_placeholder_dwuol_1bifm_97",
  "item": "awsui_item_dwuol_1bifm_102",
  "checkbox": "awsui_checkbox_dwuol_1bifm_106",
  "filter": "awsui_filter_dwuol_1bifm_115",
  "trigger": "awsui_trigger_dwuol_1bifm_120",
  "layout-strut": "awsui_layout-strut_dwuol_1bifm_126",
  "list-bottom": "awsui_list-bottom_dwuol_1bifm_132",
  "selected-icon": "awsui_selected-icon_dwuol_1bifm_136",
  "show-label-tag": "awsui_show-label-tag_dwuol_1bifm_140",
  "inline-token-trigger": "awsui_inline-token-trigger_dwuol_1bifm_144",
  "inline-token-list": "awsui_inline-token-list_dwuol_1bifm_151",
  "inline-token": "awsui_inline-token_dwuol_1bifm_144",
  "visual-refresh": "awsui_visual-refresh_dwuol_1bifm_181",
  "inline-token-hidden-placeholder": "awsui_inline-token-hidden-placeholder_dwuol_1bifm_188",
  "inline-token-counter": "awsui_inline-token-counter_dwuol_1bifm_194",
  "inline-token-trigger--disabled": "awsui_inline-token-trigger--disabled_dwuol_1bifm_198",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_dwuol_1bifm_204"
};
  