
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_10v1l_197",
  "refresh": "awsui_refresh_1r9lg_10v1l_197",
  "animating": "awsui_animating_1r9lg_10v1l_211",
  "drawer-closed": "awsui_drawer-closed_1r9lg_10v1l_266",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_10v1l_271",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_10v1l_287",
  "position-bottom": "awsui_position-bottom_1r9lg_10v1l_294",
  "with-toolbar": "awsui_with-toolbar_1r9lg_10v1l_311",
  "position-side": "awsui_position-side_1r9lg_10v1l_324",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_10v1l_330",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_10v1l_341",
  "open-button-side": "awsui_open-button-side_1r9lg_10v1l_352",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_10v1l_361",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_10v1l_371",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_10v1l_375",
  "content-bottom": "awsui_content-bottom_1r9lg_10v1l_384",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_10v1l_400",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_10v1l_405",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_10v1l_409",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_10v1l_413",
  "content-side": "awsui_content-side_1r9lg_10v1l_418",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_10v1l_428",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_10v1l_434",
  "header": "awsui_header_1r9lg_10v1l_442",
  "header-text": "awsui_header-text_1r9lg_10v1l_452",
  "header-actions": "awsui_header-actions_1r9lg_10v1l_465",
  "divider": "awsui_divider_1r9lg_10v1l_473"
};
  