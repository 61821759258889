
    import './styles.scoped.css';
    export default {
  "expandable-toggle-wrapper": "awsui_expandable-toggle-wrapper_c6tup_1szmt_97",
  "body-cell": "awsui_body-cell_c6tup_1szmt_104",
  "body-cell-content": "awsui_body-cell-content_c6tup_1szmt_112",
  "expandable-level-0": "awsui_expandable-level-0_c6tup_1szmt_118",
  "expandable-level-1": "awsui_expandable-level-1_c6tup_1szmt_124",
  "expandable-level-2": "awsui_expandable-level-2_c6tup_1szmt_130",
  "expandable-level-3": "awsui_expandable-level-3_c6tup_1szmt_136",
  "expandable-level-4": "awsui_expandable-level-4_c6tup_1szmt_142",
  "expandable-level-5": "awsui_expandable-level-5_c6tup_1szmt_148",
  "expandable-level-6": "awsui_expandable-level-6_c6tup_1szmt_154",
  "expandable-level-7": "awsui_expandable-level-7_c6tup_1szmt_160",
  "expandable-level-8": "awsui_expandable-level-8_c6tup_1szmt_166",
  "expandable-level-9": "awsui_expandable-level-9_c6tup_1szmt_172",
  "expandable-level-next": "awsui_expandable-level-next_c6tup_1szmt_178",
  "body-cell-wrap": "awsui_body-cell-wrap_c6tup_1szmt_198",
  "is-visual-refresh": "awsui_is-visual-refresh_c6tup_1szmt_212",
  "has-striped-rows": "awsui_has-striped-rows_c6tup_1szmt_224",
  "body-cell-edit-active": "awsui_body-cell-edit-active_c6tup_1szmt_296",
  "body-cell-interactive": "awsui_body-cell-interactive_c6tup_1szmt_296",
  "body-cell-editable": "awsui_body-cell-editable_c6tup_1szmt_296",
  "has-striped-rows-sticky-cell-pad-inline-start": "awsui_has-striped-rows-sticky-cell-pad-inline-start_c6tup_1szmt_440",
  "has-selection": "awsui_has-selection_c6tup_1szmt_512",
  "body-cell-first-row": "awsui_body-cell-first-row_c6tup_1szmt_591",
  "body-cell-last-row": "awsui_body-cell-last-row_c6tup_1szmt_594",
  "body-cell-selected": "awsui_body-cell-selected_c6tup_1szmt_594",
  "has-footer": "awsui_has-footer_c6tup_1szmt_594",
  "body-cell-shaded": "awsui_body-cell-shaded_c6tup_1szmt_604",
  "sticky-cell": "awsui_sticky-cell_c6tup_1szmt_632",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_c6tup_1szmt_640",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_c6tup_1szmt_726",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_c6tup_1szmt_735",
  "body-cell-next-selected": "awsui_body-cell-next-selected_c6tup_1szmt_768",
  "body-cell-prev-selected": "awsui_body-cell-prev-selected_c6tup_1szmt_774",
  "body-cell-editor-wrapper": "awsui_body-cell-editor-wrapper_c6tup_1szmt_800",
  "body-cell-success": "awsui_body-cell-success_c6tup_1szmt_807",
  "body-cell-editor": "awsui_body-cell-editor_c6tup_1szmt_800",
  "body-cell-editor-disabled": "awsui_body-cell-editor-disabled_c6tup_1szmt_834",
  "body-cell-editor-form": "awsui_body-cell-editor-form_c6tup_1szmt_843",
  "body-cell-editor-row": "awsui_body-cell-editor-row_c6tup_1szmt_853",
  "body-cell-editor-controls": "awsui_body-cell-editor-controls_c6tup_1szmt_863",
  "body-cell-editor-row-editor": "awsui_body-cell-editor-row-editor_c6tup_1szmt_866",
  "body-cell-expandable": "awsui_body-cell-expandable_c6tup_1szmt_869",
  "resizable-columns": "awsui_resizable-columns_c6tup_1szmt_890",
  "expandable-cell-content": "awsui_expandable-cell-content_c6tup_1szmt_893",
  "body-cell-has-success": "awsui_body-cell-has-success_c6tup_1szmt_962",
  "body-cell-edit-disabled-popover": "awsui_body-cell-edit-disabled-popover_c6tup_1szmt_962"
};
  