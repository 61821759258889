
    import './styles.scoped.css';
    export default {
  "box": "awsui_box_18wu0_16wz6_108",
  "p-variant": "awsui_p-variant_18wu0_16wz6_108",
  "color-default": "awsui_color-default_18wu0_16wz6_108",
  "b-variant": "awsui_b-variant_18wu0_16wz6_108",
  "strong-variant": "awsui_strong-variant_18wu0_16wz6_108",
  "code-variant": "awsui_code-variant_18wu0_16wz6_108",
  "pre-variant": "awsui_pre-variant_18wu0_16wz6_108",
  "samp-variant": "awsui_samp-variant_18wu0_16wz6_108",
  "h1-variant": "awsui_h1-variant_18wu0_16wz6_112",
  "h2-variant": "awsui_h2-variant_18wu0_16wz6_112",
  "h3-variant": "awsui_h3-variant_18wu0_16wz6_112",
  "h4-variant": "awsui_h4-variant_18wu0_16wz6_112",
  "h5-variant": "awsui_h5-variant_18wu0_16wz6_112",
  "small-variant": "awsui_small-variant_18wu0_16wz6_116",
  "a-variant": "awsui_a-variant_18wu0_16wz6_120",
  "font-size-default": "awsui_font-size-default_18wu0_16wz6_124",
  "font-weight-default": "awsui_font-weight-default_18wu0_16wz6_164",
  "key-label-variant": "awsui_key-label-variant_18wu0_16wz6_206",
  "gen-ai-label-variant": "awsui_gen-ai-label-variant_18wu0_16wz6_213",
  "value-large-variant": "awsui_value-large-variant_18wu0_16wz6_220",
  "font-weight-heavy": "awsui_font-weight-heavy_18wu0_16wz6_227",
  "color-inverted": "awsui_color-inverted_18wu0_16wz6_232",
  "color-text-label": "awsui_color-text-label_18wu0_16wz6_235",
  "color-text-body-secondary": "awsui_color-text-body-secondary_18wu0_16wz6_238",
  "color-text-status-error": "awsui_color-text-status-error_18wu0_16wz6_241",
  "color-text-status-success": "awsui_color-text-status-success_18wu0_16wz6_244",
  "color-text-status-info": "awsui_color-text-status-info_18wu0_16wz6_247",
  "color-text-status-inactive": "awsui_color-text-status-inactive_18wu0_16wz6_250",
  "color-text-status-warning": "awsui_color-text-status-warning_18wu0_16wz6_253",
  "color-inherit": "awsui_color-inherit_18wu0_16wz6_256",
  "font-size-body-s": "awsui_font-size-body-s_18wu0_16wz6_259",
  "font-size-body-m": "awsui_font-size-body-m_18wu0_16wz6_264",
  "font-size-heading-xs": "awsui_font-size-heading-xs_18wu0_16wz6_268",
  "font-size-heading-s": "awsui_font-size-heading-s_18wu0_16wz6_272",
  "font-size-heading-m": "awsui_font-size-heading-m_18wu0_16wz6_277",
  "font-size-heading-l": "awsui_font-size-heading-l_18wu0_16wz6_282",
  "font-size-heading-xl": "awsui_font-size-heading-xl_18wu0_16wz6_287",
  "font-size-display-l": "awsui_font-size-display-l_18wu0_16wz6_292",
  "font-weight-light": "awsui_font-weight-light_18wu0_16wz6_297",
  "font-weight-normal": "awsui_font-weight-normal_18wu0_16wz6_300",
  "font-weight-bold": "awsui_font-weight-bold_18wu0_16wz6_303",
  "t-left": "awsui_t-left_18wu0_16wz6_310",
  "t-right": "awsui_t-right_18wu0_16wz6_314",
  "t-center": "awsui_t-center_18wu0_16wz6_318",
  "p-n": "awsui_p-n_18wu0_16wz6_326",
  "p-top-n": "awsui_p-top-n_18wu0_16wz6_331",
  "p-vertical-n": "awsui_p-vertical-n_18wu0_16wz6_332",
  "p-right-n": "awsui_p-right-n_18wu0_16wz6_336",
  "p-horizontal-n": "awsui_p-horizontal-n_18wu0_16wz6_337",
  "p-bottom-n": "awsui_p-bottom-n_18wu0_16wz6_341",
  "p-left-n": "awsui_p-left-n_18wu0_16wz6_346",
  "p-xxxs": "awsui_p-xxxs_18wu0_16wz6_351",
  "p-top-xxxs": "awsui_p-top-xxxs_18wu0_16wz6_356",
  "p-vertical-xxxs": "awsui_p-vertical-xxxs_18wu0_16wz6_357",
  "p-right-xxxs": "awsui_p-right-xxxs_18wu0_16wz6_361",
  "p-horizontal-xxxs": "awsui_p-horizontal-xxxs_18wu0_16wz6_362",
  "p-bottom-xxxs": "awsui_p-bottom-xxxs_18wu0_16wz6_366",
  "p-left-xxxs": "awsui_p-left-xxxs_18wu0_16wz6_371",
  "p-xxs": "awsui_p-xxs_18wu0_16wz6_376",
  "p-top-xxs": "awsui_p-top-xxs_18wu0_16wz6_381",
  "p-vertical-xxs": "awsui_p-vertical-xxs_18wu0_16wz6_382",
  "p-right-xxs": "awsui_p-right-xxs_18wu0_16wz6_386",
  "p-horizontal-xxs": "awsui_p-horizontal-xxs_18wu0_16wz6_387",
  "p-bottom-xxs": "awsui_p-bottom-xxs_18wu0_16wz6_391",
  "p-left-xxs": "awsui_p-left-xxs_18wu0_16wz6_396",
  "p-xs": "awsui_p-xs_18wu0_16wz6_401",
  "p-top-xs": "awsui_p-top-xs_18wu0_16wz6_406",
  "p-vertical-xs": "awsui_p-vertical-xs_18wu0_16wz6_407",
  "p-right-xs": "awsui_p-right-xs_18wu0_16wz6_411",
  "p-horizontal-xs": "awsui_p-horizontal-xs_18wu0_16wz6_412",
  "p-bottom-xs": "awsui_p-bottom-xs_18wu0_16wz6_416",
  "p-left-xs": "awsui_p-left-xs_18wu0_16wz6_421",
  "p-s": "awsui_p-s_18wu0_16wz6_426",
  "p-top-s": "awsui_p-top-s_18wu0_16wz6_431",
  "p-vertical-s": "awsui_p-vertical-s_18wu0_16wz6_432",
  "p-right-s": "awsui_p-right-s_18wu0_16wz6_436",
  "p-horizontal-s": "awsui_p-horizontal-s_18wu0_16wz6_437",
  "p-bottom-s": "awsui_p-bottom-s_18wu0_16wz6_441",
  "p-left-s": "awsui_p-left-s_18wu0_16wz6_446",
  "p-m": "awsui_p-m_18wu0_16wz6_451",
  "p-top-m": "awsui_p-top-m_18wu0_16wz6_456",
  "p-vertical-m": "awsui_p-vertical-m_18wu0_16wz6_457",
  "p-right-m": "awsui_p-right-m_18wu0_16wz6_461",
  "p-horizontal-m": "awsui_p-horizontal-m_18wu0_16wz6_462",
  "p-bottom-m": "awsui_p-bottom-m_18wu0_16wz6_466",
  "p-left-m": "awsui_p-left-m_18wu0_16wz6_471",
  "p-l": "awsui_p-l_18wu0_16wz6_346",
  "p-top-l": "awsui_p-top-l_18wu0_16wz6_481",
  "p-vertical-l": "awsui_p-vertical-l_18wu0_16wz6_482",
  "p-right-l": "awsui_p-right-l_18wu0_16wz6_486",
  "p-horizontal-l": "awsui_p-horizontal-l_18wu0_16wz6_487",
  "p-bottom-l": "awsui_p-bottom-l_18wu0_16wz6_491",
  "p-left-l": "awsui_p-left-l_18wu0_16wz6_496",
  "p-xl": "awsui_p-xl_18wu0_16wz6_501",
  "p-top-xl": "awsui_p-top-xl_18wu0_16wz6_506",
  "p-vertical-xl": "awsui_p-vertical-xl_18wu0_16wz6_507",
  "p-right-xl": "awsui_p-right-xl_18wu0_16wz6_511",
  "p-horizontal-xl": "awsui_p-horizontal-xl_18wu0_16wz6_512",
  "p-bottom-xl": "awsui_p-bottom-xl_18wu0_16wz6_516",
  "p-left-xl": "awsui_p-left-xl_18wu0_16wz6_521",
  "p-xxl": "awsui_p-xxl_18wu0_16wz6_526",
  "p-top-xxl": "awsui_p-top-xxl_18wu0_16wz6_531",
  "p-vertical-xxl": "awsui_p-vertical-xxl_18wu0_16wz6_532",
  "p-right-xxl": "awsui_p-right-xxl_18wu0_16wz6_536",
  "p-horizontal-xxl": "awsui_p-horizontal-xxl_18wu0_16wz6_537",
  "p-bottom-xxl": "awsui_p-bottom-xxl_18wu0_16wz6_541",
  "p-left-xxl": "awsui_p-left-xxl_18wu0_16wz6_546",
  "p-xxxl": "awsui_p-xxxl_18wu0_16wz6_551",
  "p-top-xxxl": "awsui_p-top-xxxl_18wu0_16wz6_556",
  "p-vertical-xxxl": "awsui_p-vertical-xxxl_18wu0_16wz6_557",
  "p-right-xxxl": "awsui_p-right-xxxl_18wu0_16wz6_561",
  "p-horizontal-xxxl": "awsui_p-horizontal-xxxl_18wu0_16wz6_562",
  "p-bottom-xxxl": "awsui_p-bottom-xxxl_18wu0_16wz6_566",
  "p-left-xxxl": "awsui_p-left-xxxl_18wu0_16wz6_571",
  "m-n": "awsui_m-n_18wu0_16wz6_576",
  "m-top-n": "awsui_m-top-n_18wu0_16wz6_581",
  "m-vertical-n": "awsui_m-vertical-n_18wu0_16wz6_582",
  "m-right-n": "awsui_m-right-n_18wu0_16wz6_586",
  "m-horizontal-n": "awsui_m-horizontal-n_18wu0_16wz6_587",
  "m-bottom-n": "awsui_m-bottom-n_18wu0_16wz6_591",
  "m-left-n": "awsui_m-left-n_18wu0_16wz6_596",
  "m-xxxs": "awsui_m-xxxs_18wu0_16wz6_601",
  "m-top-xxxs": "awsui_m-top-xxxs_18wu0_16wz6_606",
  "m-vertical-xxxs": "awsui_m-vertical-xxxs_18wu0_16wz6_607",
  "m-right-xxxs": "awsui_m-right-xxxs_18wu0_16wz6_611",
  "m-horizontal-xxxs": "awsui_m-horizontal-xxxs_18wu0_16wz6_612",
  "m-bottom-xxxs": "awsui_m-bottom-xxxs_18wu0_16wz6_616",
  "m-left-xxxs": "awsui_m-left-xxxs_18wu0_16wz6_621",
  "m-xxs": "awsui_m-xxs_18wu0_16wz6_626",
  "m-top-xxs": "awsui_m-top-xxs_18wu0_16wz6_631",
  "m-vertical-xxs": "awsui_m-vertical-xxs_18wu0_16wz6_632",
  "m-right-xxs": "awsui_m-right-xxs_18wu0_16wz6_636",
  "m-horizontal-xxs": "awsui_m-horizontal-xxs_18wu0_16wz6_637",
  "m-bottom-xxs": "awsui_m-bottom-xxs_18wu0_16wz6_641",
  "m-left-xxs": "awsui_m-left-xxs_18wu0_16wz6_646",
  "m-xs": "awsui_m-xs_18wu0_16wz6_651",
  "m-top-xs": "awsui_m-top-xs_18wu0_16wz6_656",
  "m-vertical-xs": "awsui_m-vertical-xs_18wu0_16wz6_657",
  "m-right-xs": "awsui_m-right-xs_18wu0_16wz6_661",
  "m-horizontal-xs": "awsui_m-horizontal-xs_18wu0_16wz6_662",
  "m-bottom-xs": "awsui_m-bottom-xs_18wu0_16wz6_666",
  "m-left-xs": "awsui_m-left-xs_18wu0_16wz6_671",
  "m-s": "awsui_m-s_18wu0_16wz6_676",
  "m-top-s": "awsui_m-top-s_18wu0_16wz6_681",
  "m-vertical-s": "awsui_m-vertical-s_18wu0_16wz6_682",
  "m-right-s": "awsui_m-right-s_18wu0_16wz6_686",
  "m-horizontal-s": "awsui_m-horizontal-s_18wu0_16wz6_687",
  "m-bottom-s": "awsui_m-bottom-s_18wu0_16wz6_691",
  "m-left-s": "awsui_m-left-s_18wu0_16wz6_696",
  "m-m": "awsui_m-m_18wu0_16wz6_701",
  "m-top-m": "awsui_m-top-m_18wu0_16wz6_706",
  "m-vertical-m": "awsui_m-vertical-m_18wu0_16wz6_707",
  "m-right-m": "awsui_m-right-m_18wu0_16wz6_711",
  "m-horizontal-m": "awsui_m-horizontal-m_18wu0_16wz6_712",
  "m-bottom-m": "awsui_m-bottom-m_18wu0_16wz6_716",
  "m-left-m": "awsui_m-left-m_18wu0_16wz6_721",
  "m-l": "awsui_m-l_18wu0_16wz6_596",
  "m-top-l": "awsui_m-top-l_18wu0_16wz6_731",
  "m-vertical-l": "awsui_m-vertical-l_18wu0_16wz6_732",
  "m-right-l": "awsui_m-right-l_18wu0_16wz6_736",
  "m-horizontal-l": "awsui_m-horizontal-l_18wu0_16wz6_737",
  "m-bottom-l": "awsui_m-bottom-l_18wu0_16wz6_741",
  "m-left-l": "awsui_m-left-l_18wu0_16wz6_746",
  "m-xl": "awsui_m-xl_18wu0_16wz6_751",
  "m-top-xl": "awsui_m-top-xl_18wu0_16wz6_756",
  "m-vertical-xl": "awsui_m-vertical-xl_18wu0_16wz6_757",
  "m-right-xl": "awsui_m-right-xl_18wu0_16wz6_761",
  "m-horizontal-xl": "awsui_m-horizontal-xl_18wu0_16wz6_762",
  "m-bottom-xl": "awsui_m-bottom-xl_18wu0_16wz6_766",
  "m-left-xl": "awsui_m-left-xl_18wu0_16wz6_771",
  "m-xxl": "awsui_m-xxl_18wu0_16wz6_776",
  "m-top-xxl": "awsui_m-top-xxl_18wu0_16wz6_781",
  "m-vertical-xxl": "awsui_m-vertical-xxl_18wu0_16wz6_782",
  "m-right-xxl": "awsui_m-right-xxl_18wu0_16wz6_786",
  "m-horizontal-xxl": "awsui_m-horizontal-xxl_18wu0_16wz6_787",
  "m-bottom-xxl": "awsui_m-bottom-xxl_18wu0_16wz6_791",
  "m-left-xxl": "awsui_m-left-xxl_18wu0_16wz6_796",
  "m-xxxl": "awsui_m-xxxl_18wu0_16wz6_801",
  "m-top-xxxl": "awsui_m-top-xxxl_18wu0_16wz6_806",
  "m-vertical-xxxl": "awsui_m-vertical-xxxl_18wu0_16wz6_807",
  "m-right-xxxl": "awsui_m-right-xxxl_18wu0_16wz6_811",
  "m-horizontal-xxxl": "awsui_m-horizontal-xxxl_18wu0_16wz6_812",
  "m-bottom-xxxl": "awsui_m-bottom-xxxl_18wu0_16wz6_816",
  "m-left-xxxl": "awsui_m-left-xxxl_18wu0_16wz6_821",
  "d-block": "awsui_d-block_18wu0_16wz6_826",
  "d-inline": "awsui_d-inline_18wu0_16wz6_829",
  "d-inline-block": "awsui_d-inline-block_18wu0_16wz6_832",
  "d-none": "awsui_d-none_18wu0_16wz6_835",
  "f-left": "awsui_f-left_18wu0_16wz6_839",
  "f-right": "awsui_f-right_18wu0_16wz6_843",
  "root": "awsui_root_18wu0_16wz6_851"
};
  