
    import './styles.scoped.css';
    export default {
  "sticky-scrollbar": "awsui_sticky-scrollbar_faqt8_xzs7h_97",
  "sticky-scrollbar-content": "awsui_sticky-scrollbar-content_faqt8_xzs7h_106",
  "sticky-scrollbar-visible": "awsui_sticky-scrollbar-visible_faqt8_xzs7h_109",
  "sticky-scrollbar-native-invisible": "awsui_sticky-scrollbar-native-invisible_faqt8_xzs7h_112",
  "sticky-scrollbar-offset": "awsui_sticky-scrollbar-offset_faqt8_xzs7h_115",
  "is-visual-refresh": "awsui_is-visual-refresh_faqt8_xzs7h_118"
};
  