
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_1kosq_101th_97",
  "breadcrumb": "awsui_breadcrumb_1kosq_101th_101",
  "icon": "awsui_icon_1kosq_101th_104",
  "anchor": "awsui_anchor_1kosq_101th_109",
  "last": "awsui_last_1kosq_101th_155",
  "compressed": "awsui_compressed_1kosq_101th_165",
  "text": "awsui_text_1kosq_101th_169",
  "virtual-item": "awsui_virtual-item_1kosq_101th_176",
  "text-hidden": "awsui_text-hidden_1kosq_101th_183"
};
  