
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_x7peu_179a9_97",
  "resize-active-with-focus": "awsui_resize-active-with-focus_x7peu_179a9_97",
  "divider": "awsui_divider_x7peu_179a9_102",
  "divider-disabled": "awsui_divider-disabled_x7peu_179a9_116",
  "divider-active": "awsui_divider-active_x7peu_179a9_119",
  "resizer": "awsui_resizer_x7peu_179a9_123",
  "has-focus": "awsui_has-focus_x7peu_179a9_180",
  "tracker": "awsui_tracker_x7peu_179a9_203"
};
  