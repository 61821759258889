
    import './styles.scoped.css';
    export default {
  "selectable-item": "awsui_selectable-item_15o6u_1nwbb_97",
  "pad-bottom": "awsui_pad-bottom_15o6u_1nwbb_121",
  "has-background": "awsui_has-background_15o6u_1nwbb_130",
  "highlighted": "awsui_highlighted_15o6u_1nwbb_133",
  "selected": "awsui_selected_15o6u_1nwbb_133",
  "disabled": "awsui_disabled_15o6u_1nwbb_151",
  "next-item-selected": "awsui_next-item-selected_15o6u_1nwbb_161",
  "is-keyboard": "awsui_is-keyboard_15o6u_1nwbb_173",
  "parent": "awsui_parent_15o6u_1nwbb_177",
  "interactiveGroups": "awsui_interactiveGroups_15o6u_1nwbb_181",
  "child": "awsui_child_15o6u_1nwbb_199",
  "virtual": "awsui_virtual_15o6u_1nwbb_214",
  "measure-strut": "awsui_measure-strut_15o6u_1nwbb_225",
  "measure-strut-first": "awsui_measure-strut-first_15o6u_1nwbb_234",
  "screenreader-content": "awsui_screenreader-content_15o6u_1nwbb_238",
  "option-content": "awsui_option-content_15o6u_1nwbb_244"
};
  