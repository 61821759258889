// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// React is the only framework we're using.
const framework = 'react';
export function buildMetricDetail({ source, action, version, configuration }, theme) {
    const metricOrigin = typeof AWSUI_METRIC_ORIGIN !== 'undefined' ? AWSUI_METRIC_ORIGIN : 'main';
    const detailObject = {
        o: metricOrigin,
        s: source,
        t: theme,
        a: action,
        f: framework,
        v: formatMajorVersionForMetricDetail(version),
        c: configuration,
    };
    return jsonStringify(detailObject);
}
export function jsonStringify(detailObject) {
    return JSON.stringify(detailObject, detailSerializer);
}
function detailSerializer(key, value) {
    // Report NaN and Infinity as strings instead of `null` (default behavior)
    if (typeof value === 'number' && !Number.isFinite(value)) {
        return `${value}`;
    }
    return value;
}
export function buildMetricName({ source, version }, theme) {
    return ['awsui', source, `${formatVersionForMetricName(theme, version)}`].join('_');
}
export function formatMajorVersionForMetricDetail(version) {
    return version.replace(/\s/g, '');
}
export function formatVersionForMetricName(theme, version) {
    return `${theme.charAt(0)}${getMajorVersion(version).replace('.', '')}`;
}
function getMajorVersion(versionString) {
    const majorVersionMatch = versionString.match(/^(\d+\.\d+)/);
    return majorVersionMatch ? majorVersionMatch[1] : '';
}
