
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_1ykar_tn512_97",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1ykar_tn512_1",
  "root": "awsui_root_1ykar_tn512_128",
  "calendar-inner": "awsui_calendar-inner_1ykar_tn512_169",
  "calendar-header": "awsui_calendar-header_1ykar_tn512_173",
  "calendar-header-title": "awsui_calendar-header-title_1ykar_tn512_178",
  "calendar-next-btn": "awsui_calendar-next-btn_1ykar_tn512_186",
  "calendar-prev-btn": "awsui_calendar-prev-btn_1ykar_tn512_189",
  "calendar-grid": "awsui_calendar-grid_1ykar_tn512_192",
  "calendar-grid-dense": "awsui_calendar-grid-dense_1ykar_tn512_196",
  "calendar-grid-cell": "awsui_calendar-grid-cell_1ykar_tn512_204",
  "calendar-date-header": "awsui_calendar-date-header_1ykar_tn512_209",
  "calendar-date": "awsui_calendar-date_1ykar_tn512_209",
  "calendar-date-enabled": "awsui_calendar-date-enabled_1ykar_tn512_229",
  "calendar-date-current-page": "awsui_calendar-date-current-page_1ykar_tn512_239",
  "calendar-date-selected": "awsui_calendar-date-selected_1ykar_tn512_250",
  "calendar-date-current": "awsui_calendar-date-current_1ykar_tn512_239",
  "calendar-date-dense": "awsui_calendar-date-dense_1ykar_tn512_271",
  "date-inner": "awsui_date-inner_1ykar_tn512_279",
  "calendar-row": "awsui_calendar-row_1ykar_tn512_354",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_1ykar_tn512_358"
};
  