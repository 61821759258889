
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1612d_andhz_131",
  "spinner-rotator": "awsui_spinner-rotator_1612d_andhz_1",
  "size-normal": "awsui_size-normal_1612d_andhz_146",
  "size-big": "awsui_size-big_1612d_andhz_154",
  "size-large": "awsui_size-large_1612d_andhz_162",
  "variant-normal": "awsui_variant-normal_1612d_andhz_170",
  "variant-disabled": "awsui_variant-disabled_1612d_andhz_173",
  "variant-inverted": "awsui_variant-inverted_1612d_andhz_176",
  "circle": "awsui_circle_1612d_andhz_188",
  "circle-left": "awsui_circle-left_1612d_andhz_216",
  "spinner-line-left": "awsui_spinner-line-left_1612d_andhz_1",
  "circle-right": "awsui_circle-right_1612d_andhz_221",
  "spinner-line-right": "awsui_spinner-line-right_1612d_andhz_1"
};
  