// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export const awsuiVisualRefreshFlag = Symbol.for('awsui-visual-refresh-flag');
export const awsuiGlobalFlagsSymbol = Symbol.for('awsui-global-flags');
export const getTopWindow = () => {
    return window.top;
};
export function getGlobal() {
    return (typeof window !== 'undefined' ? window : globalThis);
}
function readFlag(holder, flagName) {
    var _a;
    return (_a = holder === null || holder === void 0 ? void 0 : holder[awsuiGlobalFlagsSymbol]) === null || _a === void 0 ? void 0 : _a[flagName];
}
export const getGlobalFlag = (flagName) => {
    try {
        const ownFlag = readFlag(getGlobal(), flagName);
        if (ownFlag !== undefined) {
            return ownFlag;
        }
        return readFlag(getTopWindow(), flagName);
    }
    catch (e) {
        return undefined;
    }
};
export const setGlobalFlag = (flagName, value) => {
    const holder = getGlobal();
    if (value === undefined) {
        const currentValue = readFlag(holder, flagName);
        if (currentValue !== undefined) {
            delete holder[awsuiGlobalFlagsSymbol][flagName];
        }
    }
    else {
        holder[awsuiGlobalFlagsSymbol] = holder[awsuiGlobalFlagsSymbol] || {};
        holder[awsuiGlobalFlagsSymbol][flagName] = value;
    }
};
