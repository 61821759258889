// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { isDevelopment } from './is-development';
const messageCache = new Set();
export function warnOnce(component, message) {
    if (isDevelopment) {
        const warning = `[AwsUi] [${component}] ${message}`;
        if (!messageCache.has(warning)) {
            messageCache.add(warning);
            console.warn(warning);
        }
    }
}
export function clearMessageCache() {
    messageCache.clear();
}
