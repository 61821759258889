
    import './styles.scoped.css';
    export default {
  "visible-content": "awsui_visible-content_tc96w_vl685_193",
  "visible-content-toggle": "awsui_visible-content-toggle_tc96w_vl685_194",
  "visible-content-groups": "awsui_visible-content-groups_tc96w_vl685_195",
  "visible-content-group": "awsui_visible-content-group_tc96w_vl685_195",
  "visible-content-title": "awsui_visible-content-title_tc96w_vl685_200",
  "visible-content-group-label": "awsui_visible-content-group-label_tc96w_vl685_210",
  "visible-content-option": "awsui_visible-content-option_tc96w_vl685_216",
  "visible-content-option-label": "awsui_visible-content-option-label_tc96w_vl685_229",
  "content-display-option-toggle": "awsui_content-display-option-toggle_tc96w_vl685_428",
  "content-display-option-content": "awsui_content-display-option-content_tc96w_vl685_432",
  "content-display-option": "awsui_content-display-option_tc96w_vl685_428",
  "placeholder": "awsui_placeholder_tc96w_vl685_482",
  "sorting": "awsui_sorting_tc96w_vl685_482",
  "content-display-option-label": "awsui_content-display-option-label_tc96w_vl685_509",
  "drag-overlay": "awsui_drag-overlay_tc96w_vl685_516",
  "content-display": "awsui_content-display_tc96w_vl685_428",
  "content-display-title": "awsui_content-display-title_tc96w_vl685_549",
  "content-display-description": "awsui_content-display-description_tc96w_vl685_558",
  "content-display-option-list": "awsui_content-display-option-list_tc96w_vl685_566",
  "root": "awsui_root_tc96w_vl685_573",
  "modal-root": "awsui_modal-root_tc96w_vl685_574",
  "trigger-button": "awsui_trigger-button_tc96w_vl685_575",
  "cancel-button": "awsui_cancel-button_tc96w_vl685_576",
  "confirm-button": "awsui_confirm-button_tc96w_vl685_577",
  "custom": "awsui_custom_tc96w_vl685_578",
  "second-column-small": "awsui_second-column-small_tc96w_vl685_582",
  "wrap-lines": "awsui_wrap-lines_tc96w_vl685_586",
  "striped-rows": "awsui_striped-rows_tc96w_vl685_587",
  "content-density": "awsui_content-density_tc96w_vl685_588",
  "page-size": "awsui_page-size_tc96w_vl685_589",
  "page-size-form-field": "awsui_page-size-form-field_tc96w_vl685_590",
  "page-size-radio-group": "awsui_page-size-radio-group_tc96w_vl685_591",
  "sticky-columns": "awsui_sticky-columns_tc96w_vl685_592",
  "sticky-columns-form-field": "awsui_sticky-columns-form-field_tc96w_vl685_593",
  "sticky-columns-radio-group": "awsui_sticky-columns-radio-group_tc96w_vl685_594",
  "sticky-columns-first": "awsui_sticky-columns-first_tc96w_vl685_595",
  "sticky-columns-last": "awsui_sticky-columns-last_tc96w_vl685_596"
};
  