
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_1qo46_101",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_1qo46_150",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_1qo46_165",
  "navigation": "awsui_navigation_7nfqu_1qo46_180",
  "tools": "awsui_tools_7nfqu_1qo46_181",
  "split-panel-side": "awsui_split-panel-side_7nfqu_1qo46_205",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_1qo46_211",
  "panel-hidden": "awsui_panel-hidden_7nfqu_1qo46_224",
  "toolbar-container": "awsui_toolbar-container_7nfqu_1qo46_228",
  "notifications-container": "awsui_notifications-container_7nfqu_1qo46_234",
  "main-landmark": "awsui_main-landmark_7nfqu_1qo46_241",
  "main": "awsui_main_7nfqu_1qo46_241",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_1qo46_250",
  "content-header": "awsui_content-header_7nfqu_1qo46_260",
  "content": "awsui_content_7nfqu_1qo46_260",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_1qo46_269"
};
  