
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_1wepg_s8qpr_97",
  "description": "awsui_description_1wepg_s8qpr_98",
  "label": "awsui_label_1wepg_s8qpr_99",
  "outline": "awsui_outline_1wepg_s8qpr_107",
  "show-outline": "awsui_show-outline_1wepg_s8qpr_110",
  "native-input": "awsui_native-input_1wepg_s8qpr_114",
  "wrapper": "awsui_wrapper_1wepg_s8qpr_118",
  "label-wrapper": "awsui_label-wrapper_1wepg_s8qpr_124",
  "empty-content": "awsui_empty-content_1wepg_s8qpr_135",
  "description-bottom-padding": "awsui_description-bottom-padding_1wepg_s8qpr_145",
  "label-disabled": "awsui_label-disabled_1wepg_s8qpr_153",
  "description-disabled": "awsui_description-disabled_1wepg_s8qpr_154",
  "control": "awsui_control_1wepg_s8qpr_158"
};
  