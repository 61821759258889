
    import './styles.scoped.css';
    export default {
  "header": "awsui_header_16mm3_qvubm_97",
  "disabled": "awsui_disabled_16mm3_qvubm_114",
  "expandable-header": "awsui_expandable-header_16mm3_qvubm_118",
  "rolled-down": "awsui_rolled-down_16mm3_qvubm_129",
  "highlighted": "awsui_highlighted_16mm3_qvubm_132",
  "is-focused": "awsui_is-focused_16mm3_qvubm_150",
  "category": "awsui_category_16mm3_qvubm_155",
  "expandable": "awsui_expandable_16mm3_qvubm_118",
  "variant-navigation": "awsui_variant-navigation_16mm3_qvubm_170",
  "expand-icon": "awsui_expand-icon_16mm3_qvubm_177",
  "expand-icon-up": "awsui_expand-icon-up_16mm3_qvubm_184",
  "expand-icon-right": "awsui_expand-icon-right_16mm3_qvubm_187",
  "items-list-container": "awsui_items-list-container_16mm3_qvubm_205",
  "in-dropdown": "awsui_in-dropdown_16mm3_qvubm_214"
};
  