
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_wih1l_rg19l_101",
  "tools": "awsui_tools_wih1l_rg19l_112",
  "tools-filtering": "awsui_tools-filtering_wih1l_rg19l_120",
  "tools-align-right": "awsui_tools-align-right_wih1l_rg19l_134",
  "tools-pagination": "awsui_tools-pagination_wih1l_rg19l_138",
  "tools-preferences": "awsui_tools-preferences_wih1l_rg19l_138",
  "tools-small": "awsui_tools-small_wih1l_rg19l_144",
  "table": "awsui_table_wih1l_rg19l_150",
  "table-layout-fixed": "awsui_table-layout-fixed_wih1l_rg19l_156",
  "wrapper": "awsui_wrapper_wih1l_rg19l_160",
  "variant-stacked": "awsui_variant-stacked_wih1l_rg19l_167",
  "wrapper-content-measure": "awsui_wrapper-content-measure_wih1l_rg19l_167",
  "variant-container": "awsui_variant-container_wih1l_rg19l_167",
  "has-footer": "awsui_has-footer_wih1l_rg19l_170",
  "has-header": "awsui_has-header_wih1l_rg19l_173",
  "cell-merged": "awsui_cell-merged_wih1l_rg19l_190",
  "cell-merged-content": "awsui_cell-merged-content_wih1l_rg19l_202",
  "empty": "awsui_empty_wih1l_rg19l_218",
  "loading": "awsui_loading_wih1l_rg19l_222",
  "selection-control": "awsui_selection-control_wih1l_rg19l_231",
  "selection-control-header": "awsui_selection-control-header_wih1l_rg19l_238",
  "header-secondary": "awsui_header-secondary_wih1l_rg19l_244",
  "variant-full-page": "awsui_variant-full-page_wih1l_rg19l_256",
  "table-has-header": "awsui_table-has-header_wih1l_rg19l_265",
  "header-controls": "awsui_header-controls_wih1l_rg19l_269",
  "variant-embedded": "awsui_variant-embedded_wih1l_rg19l_278",
  "variant-borderless": "awsui_variant-borderless_wih1l_rg19l_278",
  "footer-wrapper": "awsui_footer-wrapper_wih1l_rg19l_283",
  "footer": "awsui_footer_wih1l_rg19l_283",
  "footer-with-pagination": "awsui_footer-with-pagination_wih1l_rg19l_291",
  "footer-pagination": "awsui_footer-pagination_wih1l_rg19l_299",
  "thead-active": "awsui_thead-active_wih1l_rg19l_303",
  "row": "awsui_row_wih1l_rg19l_304",
  "row-selected": "awsui_row-selected_wih1l_rg19l_305"
};
  