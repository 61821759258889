
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_dm8gx_r6wxu_101",
  "has-items": "awsui_has-items_dm8gx_r6wxu_135",
  "no-padding": "awsui_no-padding_dm8gx_r6wxu_135",
  "dismiss-button": "awsui_dismiss-button_dm8gx_r6wxu_139",
  "token": "awsui_token_dm8gx_r6wxu_181",
  "token-box": "awsui_token-box_dm8gx_r6wxu_188",
  "token-box-error": "awsui_token-box-error_dm8gx_r6wxu_207",
  "token-box-warning": "awsui_token-box-warning_dm8gx_r6wxu_218",
  "token-box-readonly": "awsui_token-box-readonly_dm8gx_r6wxu_229",
  "token-box-disabled": "awsui_token-box-disabled_dm8gx_r6wxu_243"
};
  