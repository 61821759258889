
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_12abv_97",
  "refresh": "awsui_refresh_2qdw9_12abv_136",
  "root-no-actions": "awsui_root-no-actions_2qdw9_12abv_136",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_12abv_139",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_12abv_139",
  "root-has-description": "awsui_root-has-description_2qdw9_12abv_139",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_12abv_148",
  "main": "awsui_main_2qdw9_12abv_158",
  "no-wrap": "awsui_no-wrap_2qdw9_12abv_168",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_12abv_174",
  "actions": "awsui_actions_2qdw9_12abv_182",
  "actions-centered": "awsui_actions-centered_2qdw9_12abv_187",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_12abv_190",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_12abv_194",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_12abv_198",
  "title": "awsui_title_2qdw9_12abv_214",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_12abv_219",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_12abv_226",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_12abv_232",
  "virtual-space": "awsui_virtual-space_2qdw9_12abv_253",
  "info": "awsui_info_2qdw9_12abv_259",
  "description": "awsui_description_2qdw9_12abv_263",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_12abv_270",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_12abv_274",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_12abv_281",
  "heading": "awsui_heading_2qdw9_12abv_290",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_12abv_301",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_12abv_306",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_12abv_311",
  "heading-text": "awsui_heading-text_2qdw9_12abv_317",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_12abv_320",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_12abv_328",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_12abv_336",
  "counter": "awsui_counter_2qdw9_12abv_345"
};
  