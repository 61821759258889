
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_1rcjl_101",
  "warning-icon-shake-wrapper": "awsui_warning-icon-shake-wrapper_14mhv_1rcjl_102",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_1rcjl_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_1rcjl_132",
  "warning-icon-scale-wrapper": "awsui_warning-icon-scale-wrapper_14mhv_1rcjl_133",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_1rcjl_1",
  "warning": "awsui_warning_14mhv_1rcjl_102",
  "error": "awsui_error_14mhv_1rcjl_101",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_1rcjl_1",
  "root": "awsui_root_14mhv_1rcjl_184",
  "label": "awsui_label_14mhv_1rcjl_220",
  "info": "awsui_info_14mhv_1rcjl_234",
  "description": "awsui_description_14mhv_1rcjl_240",
  "constraint": "awsui_constraint_14mhv_1rcjl_241",
  "hints": "awsui_hints_14mhv_1rcjl_248",
  "constraint-has-validation-text": "awsui_constraint-has-validation-text_14mhv_1rcjl_249",
  "secondary-control": "awsui_secondary-control_14mhv_1rcjl_253",
  "controls": "awsui_controls_14mhv_1rcjl_257",
  "label-hidden": "awsui_label-hidden_14mhv_1rcjl_257",
  "label-wrapper": "awsui_label-wrapper_14mhv_1rcjl_260",
  "control": "awsui_control_14mhv_1rcjl_257",
  "error__message": "awsui_error__message_14mhv_1rcjl_287",
  "warning__message": "awsui_warning__message_14mhv_1rcjl_288",
  "visually-hidden": "awsui_visually-hidden_14mhv_1rcjl_292"
};
  