// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { useEffect, useRef } from 'react';
export const COMPONENT_METADATA_KEY = '__awsuiMetadata__';
export function useComponentMetadata(componentName, packageVersion, analyticsMetadata) {
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef.current) {
            const node = elementRef.current;
            const metadata = {
                name: componentName,
                version: packageVersion,
            };
            // Only add analytics property to metadata if analytics property is non-empty
            if (analyticsMetadata && Object.keys(analyticsMetadata).length > 0) {
                metadata.analytics = analyticsMetadata;
            }
            Object.freeze(metadata);
            Object.defineProperty(node, COMPONENT_METADATA_KEY, { value: metadata, writable: false, configurable: true });
        }
    });
    return elementRef;
}
