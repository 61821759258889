
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_k5dlb_1q1vg_97",
  "top-navigation": "awsui_top-navigation_k5dlb_1q1vg_115",
  "padding-box": "awsui_padding-box_k5dlb_1q1vg_150",
  "medium": "awsui_medium_k5dlb_1q1vg_160",
  "narrow": "awsui_narrow_k5dlb_1q1vg_160",
  "virtual": "awsui_virtual_k5dlb_1q1vg_171",
  "hidden": "awsui_hidden_k5dlb_1q1vg_175",
  "\t": "awsui_\t_k5dlb_1q1vg_1",
  "identity": "awsui_identity_k5dlb_1q1vg_187",
  "identity-link": "awsui_identity-link_k5dlb_1q1vg_190",
  "no-logo": "awsui_no-logo_k5dlb_1q1vg_210",
  "logo": "awsui_logo_k5dlb_1q1vg_214",
  "title": "awsui_title_k5dlb_1q1vg_226",
  "inputs": "awsui_inputs_k5dlb_1q1vg_241",
  "search": "awsui_search_k5dlb_1q1vg_249",
  "search-expanded": "awsui_search-expanded_k5dlb_1q1vg_253",
  "utilities": "awsui_utilities_k5dlb_1q1vg_257",
  "utility-wrapper": "awsui_utility-wrapper_k5dlb_1q1vg_268",
  "utility-type-button-link": "awsui_utility-type-button-link_k5dlb_1q1vg_289",
  "utility-type-menu-dropdown": "awsui_utility-type-menu-dropdown_k5dlb_1q1vg_294",
  "utility-type-button-primary-button": "awsui_utility-type-button-primary-button_k5dlb_1q1vg_303",
  "utility-link-icon": "awsui_utility-link-icon_k5dlb_1q1vg_311",
  "utility-button-external-icon": "awsui_utility-button-external-icon_k5dlb_1q1vg_315",
  "offset-right-none": "awsui_offset-right-none_k5dlb_1q1vg_319",
  "offset-right-l": "awsui_offset-right-l_k5dlb_1q1vg_323",
  "offset-right-xxl": "awsui_offset-right-xxl_k5dlb_1q1vg_327",
  "overflow-menu-drawer": "awsui_overflow-menu-drawer_k5dlb_1q1vg_332",
  "overflow-menu": "awsui_overflow-menu_k5dlb_1q1vg_332",
  "overflow-menu-header": "awsui_overflow-menu-header_k5dlb_1q1vg_376",
  "overflow-menu-header-text": "awsui_overflow-menu-header-text_k5dlb_1q1vg_384",
  "overflow-menu-header-text--secondary": "awsui_overflow-menu-header-text--secondary_k5dlb_1q1vg_396",
  "overflow-menu-header-text--title": "awsui_overflow-menu-header-text--title_k5dlb_1q1vg_402",
  "overflow-menu-back-button": "awsui_overflow-menu-back-button_k5dlb_1q1vg_406",
  "overflow-menu-dismiss-button": "awsui_overflow-menu-dismiss-button_k5dlb_1q1vg_410",
  "overflow-menu-control": "awsui_overflow-menu-control_k5dlb_1q1vg_414",
  "overflow-menu-list-item-utility": "awsui_overflow-menu-list-item-utility_k5dlb_1q1vg_455",
  "overflow-menu-control-link": "awsui_overflow-menu-control-link_k5dlb_1q1vg_463",
  "overflow-menu-control-expandable-menu-trigger": "awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1q1vg_466",
  "overflow-menu-list": "awsui_overflow-menu-list_k5dlb_1q1vg_455",
  "overflow-menu-list-submenu": "awsui_overflow-menu-list-submenu_k5dlb_1q1vg_502",
  "overflow-menu-list-item": "awsui_overflow-menu-list-item_k5dlb_1q1vg_455",
  "overflow-menu-list-item-icon": "awsui_overflow-menu-list-item-icon_k5dlb_1q1vg_511",
  "overflow-menu-list-item-text": "awsui_overflow-menu-list-item-text_k5dlb_1q1vg_514",
  "overflow-menu-list-item-submenu": "awsui_overflow-menu-list-item-submenu_k5dlb_1q1vg_523",
  "overflow-menu-list-item-dropdown-menu": "awsui_overflow-menu-list-item-dropdown-menu_k5dlb_1q1vg_529",
  "overflow-menu-list-item-expandable": "awsui_overflow-menu-list-item-expandable_k5dlb_1q1vg_533",
  "expanded": "awsui_expanded_k5dlb_1q1vg_541"
};
  