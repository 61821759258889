
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_fae1z_97",
  "scrolling-background": "awsui_scrolling-background_hyvsj_fae1z_100",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_fae1z_111",
  "drawers-container": "awsui_drawers-container_hyvsj_fae1z_124",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_fae1z_135",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_fae1z_156",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_fae1z_161",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_fae1z_202",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_fae1z_212",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_fae1z_217",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_fae1z_231",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_fae1z_217",
  "drawer": "awsui_drawer_hyvsj_fae1z_124",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_fae1z_262",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_fae1z_271",
  "drawer-content": "awsui_drawer-content_hyvsj_fae1z_262",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_fae1z_279",
  "drawer-slider": "awsui_drawer-slider_hyvsj_fae1z_282",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_fae1z_289",
  "content": "awsui_content_hyvsj_fae1z_315",
  "layout": "awsui_layout_hyvsj_fae1z_336",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_fae1z_408",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_fae1z_423",
  "content-type-table": "awsui_content-type-table_hyvsj_fae1z_437",
  "content-type-cards": "awsui_content-type-cards_hyvsj_fae1z_437",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_fae1z_443",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_fae1z_446",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_fae1z_460",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_fae1z_463",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_fae1z_473",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_fae1z_476",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_fae1z_476",
  "has-header": "awsui_has-header_hyvsj_fae1z_476",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_fae1z_491",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_fae1z_491",
  "has-left-toggles-gutter": "awsui_has-left-toggles-gutter_hyvsj_fae1z_511",
  "has-right-toggles-gutter": "awsui_has-right-toggles-gutter_hyvsj_fae1z_514",
  "has-split-panel": "awsui_has-split-panel_hyvsj_fae1z_534",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_fae1z_534",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_fae1z_542",
  "unfocusable": "awsui_unfocusable_hyvsj_fae1z_547",
  "container": "awsui_container_hyvsj_fae1z_557",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_fae1z_583",
  "is-tools-open": "awsui_is-tools-open_hyvsj_fae1z_586",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_fae1z_586",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_fae1z_586",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_fae1z_586",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_fae1z_595",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_hyvsj_fae1z_612",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_fae1z_616",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_fae1z_620",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_fae1z_624",
  "navigation-container": "awsui_navigation-container_hyvsj_fae1z_633",
  "show-navigation": "awsui_show-navigation_hyvsj_fae1z_671",
  "animating": "awsui_animating_hyvsj_fae1z_694",
  "showButtons": "awsui_showButtons_hyvsj_fae1z_1",
  "navigation": "awsui_navigation_hyvsj_fae1z_633",
  "openNavigation": "awsui_openNavigation_hyvsj_fae1z_1",
  "animated-content": "awsui_animated-content_hyvsj_fae1z_756",
  "hide-navigation": "awsui_hide-navigation_hyvsj_fae1z_765",
  "notifications": "awsui_notifications_hyvsj_fae1z_775",
  "has-notification-content": "awsui_has-notification-content_hyvsj_fae1z_780",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_fae1z_784",
  "high-contrast": "awsui_high-contrast_hyvsj_fae1z_789",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_fae1z_807",
  "position-bottom": "awsui_position-bottom_hyvsj_fae1z_854",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_fae1z_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_fae1z_883",
  "position-side": "awsui_position-side_hyvsj_fae1z_896",
  "tools-container": "awsui_tools-container_hyvsj_fae1z_918",
  "tools": "awsui_tools_hyvsj_fae1z_918",
  "openTools": "awsui_openTools_hyvsj_fae1z_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_fae1z_1007",
  "hide-tools": "awsui_hide-tools_hyvsj_fae1z_1017",
  "show-tools": "awsui_show-tools_hyvsj_fae1z_1029",
  "has-tools-form": "awsui_has-tools-form_hyvsj_fae1z_1007",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_fae1z_1112",
  "trigger": "awsui_trigger_hyvsj_fae1z_1112",
  "selected": "awsui_selected_hyvsj_fae1z_1190",
  "badge": "awsui_badge_hyvsj_fae1z_1210",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_fae1z_1214",
  "dot": "awsui_dot_hyvsj_fae1z_1225"
};
  