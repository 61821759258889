
    import './styles.scoped.css';
    export default {
  "button-dropdown": "awsui_button-dropdown_sne0l_1b2xj_97",
  "items-list-container": "awsui_items-list-container_sne0l_1b2xj_101",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_sne0l_1b2xj_1",
  "rotate-up": "awsui_rotate-up_sne0l_1b2xj_120",
  "rotate-down": "awsui_rotate-down_sne0l_1b2xj_135",
  "header": "awsui_header_sne0l_1b2xj_150",
  "title": "awsui_title_sne0l_1b2xj_161",
  "description": "awsui_description_sne0l_1b2xj_162",
  "split-trigger-wrapper": "awsui_split-trigger-wrapper_sne0l_1b2xj_166",
  "trigger-item": "awsui_trigger-item_sne0l_1b2xj_169",
  "trigger-button": "awsui_trigger-button_sne0l_1b2xj_172",
  "visual-refresh": "awsui_visual-refresh_sne0l_1b2xj_183",
  "split-trigger": "awsui_split-trigger_sne0l_1b2xj_166",
  "dropdown-trigger": "awsui_dropdown-trigger_sne0l_1b2xj_191",
  "test-utils-button-trigger": "awsui_test-utils-button-trigger_sne0l_1b2xj_195"
};
  