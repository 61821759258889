
    import './styles.scoped.css';
    export default {
  "column-layout": "awsui_column-layout_vj6p7_tp6ux_97",
  "grid": "awsui_grid_vj6p7_tp6ux_132",
  "grid-no-gutters": "awsui_grid-no-gutters_vj6p7_tp6ux_138",
  "grid-variant-text-grid": "awsui_grid-variant-text-grid_vj6p7_tp6ux_142",
  "grid-breakpoint-default": "awsui_grid-breakpoint-default_vj6p7_tp6ux_150",
  "grid-columns-1": "awsui_grid-columns-1_vj6p7_tp6ux_153",
  "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vj6p7_tp6ux_153",
  "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vj6p7_tp6ux_156",
  "grid-columns-2": "awsui_grid-columns-2_vj6p7_tp6ux_159",
  "grid-columns-3": "awsui_grid-columns-3_vj6p7_tp6ux_165",
  "grid-columns-4": "awsui_grid-columns-4_vj6p7_tp6ux_171",
  "grid-vertical-borders": "awsui_grid-vertical-borders_vj6p7_tp6ux_187",
  "grid-horizontal-borders": "awsui_grid-horizontal-borders_vj6p7_tp6ux_220",
  "root": "awsui_root_vj6p7_tp6ux_274",
  "tile-container": "awsui_tile-container_vj6p7_tp6ux_312",
  "refresh": "awsui_refresh_vj6p7_tp6ux_327",
  "has-metadata": "awsui_has-metadata_vj6p7_tp6ux_331",
  "selected": "awsui_selected_vj6p7_tp6ux_337",
  "disabled": "awsui_disabled_vj6p7_tp6ux_342",
  "readonly": "awsui_readonly_vj6p7_tp6ux_347",
  "columns": "awsui_columns_vj6p7_tp6ux_363",
  "column-1": "awsui_column-1_vj6p7_tp6ux_369",
  "breakpoint-xs": "awsui_breakpoint-xs_vj6p7_tp6ux_376",
  "breakpoint-xxs": "awsui_breakpoint-xxs_vj6p7_tp6ux_380",
  "column-2": "awsui_column-2_vj6p7_tp6ux_384",
  "column-3": "awsui_column-3_vj6p7_tp6ux_399",
  "column-4": "awsui_column-4_vj6p7_tp6ux_414",
  "control": "awsui_control_vj6p7_tp6ux_430",
  "no-image": "awsui_no-image_vj6p7_tp6ux_434",
  "image": "awsui_image_vj6p7_tp6ux_438"
};
  