
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_14yj0_xuaq6_97",
  "no-gutters": "awsui_no-gutters_14yj0_xuaq6_136",
  "grid-column": "awsui_grid-column_14yj0_xuaq6_141",
  "colspan-1": "awsui_colspan-1_14yj0_xuaq6_152",
  "push-1": "awsui_push-1_14yj0_xuaq6_156",
  "pull-1": "awsui_pull-1_14yj0_xuaq6_159",
  "colspan-2": "awsui_colspan-2_14yj0_xuaq6_162",
  "push-2": "awsui_push-2_14yj0_xuaq6_166",
  "pull-2": "awsui_pull-2_14yj0_xuaq6_169",
  "colspan-3": "awsui_colspan-3_14yj0_xuaq6_172",
  "push-3": "awsui_push-3_14yj0_xuaq6_176",
  "pull-3": "awsui_pull-3_14yj0_xuaq6_179",
  "colspan-4": "awsui_colspan-4_14yj0_xuaq6_182",
  "push-4": "awsui_push-4_14yj0_xuaq6_186",
  "pull-4": "awsui_pull-4_14yj0_xuaq6_189",
  "colspan-5": "awsui_colspan-5_14yj0_xuaq6_192",
  "push-5": "awsui_push-5_14yj0_xuaq6_196",
  "pull-5": "awsui_pull-5_14yj0_xuaq6_199",
  "colspan-6": "awsui_colspan-6_14yj0_xuaq6_202",
  "push-6": "awsui_push-6_14yj0_xuaq6_206",
  "pull-6": "awsui_pull-6_14yj0_xuaq6_209",
  "colspan-7": "awsui_colspan-7_14yj0_xuaq6_212",
  "push-7": "awsui_push-7_14yj0_xuaq6_216",
  "pull-7": "awsui_pull-7_14yj0_xuaq6_219",
  "colspan-8": "awsui_colspan-8_14yj0_xuaq6_222",
  "push-8": "awsui_push-8_14yj0_xuaq6_226",
  "pull-8": "awsui_pull-8_14yj0_xuaq6_229",
  "colspan-9": "awsui_colspan-9_14yj0_xuaq6_232",
  "push-9": "awsui_push-9_14yj0_xuaq6_236",
  "pull-9": "awsui_pull-9_14yj0_xuaq6_239",
  "colspan-10": "awsui_colspan-10_14yj0_xuaq6_242",
  "push-10": "awsui_push-10_14yj0_xuaq6_246",
  "pull-10": "awsui_pull-10_14yj0_xuaq6_249",
  "colspan-11": "awsui_colspan-11_14yj0_xuaq6_252",
  "push-11": "awsui_push-11_14yj0_xuaq6_256",
  "pull-11": "awsui_pull-11_14yj0_xuaq6_259",
  "colspan-12": "awsui_colspan-12_14yj0_xuaq6_262",
  "push-12": "awsui_push-12_14yj0_xuaq6_266",
  "pull-12": "awsui_pull-12_14yj0_xuaq6_269",
  "push-0": "awsui_push-0_14yj0_xuaq6_272",
  "pull-0": "awsui_pull-0_14yj0_xuaq6_275",
  "offset-1": "awsui_offset-1_14yj0_xuaq6_278",
  "offset-2": "awsui_offset-2_14yj0_xuaq6_281",
  "offset-3": "awsui_offset-3_14yj0_xuaq6_284",
  "offset-4": "awsui_offset-4_14yj0_xuaq6_287",
  "offset-5": "awsui_offset-5_14yj0_xuaq6_290",
  "offset-6": "awsui_offset-6_14yj0_xuaq6_293",
  "offset-7": "awsui_offset-7_14yj0_xuaq6_296",
  "offset-8": "awsui_offset-8_14yj0_xuaq6_299",
  "offset-9": "awsui_offset-9_14yj0_xuaq6_302",
  "offset-10": "awsui_offset-10_14yj0_xuaq6_305",
  "offset-11": "awsui_offset-11_14yj0_xuaq6_308",
  "restore-pointer-events": "awsui_restore-pointer-events_14yj0_xuaq6_312"
};
  