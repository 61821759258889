
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_fvjdu_ki6j9_97",
  "button": "awsui_button_fvjdu_ki6j9_140",
  "dots": "awsui_dots_fvjdu_ki6j9_141",
  "button-disabled": "awsui_button-disabled_fvjdu_ki6j9_169",
  "arrow": "awsui_arrow_fvjdu_ki6j9_174",
  "page-number": "awsui_page-number_fvjdu_ki6j9_184",
  "button-current": "awsui_button-current_fvjdu_ki6j9_190",
  "page-item": "awsui_page-item_fvjdu_ki6j9_207",
  "root-disabled": "awsui_root-disabled_fvjdu_ki6j9_225"
};
  