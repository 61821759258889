
    import './styles.scoped.css';
    export default {
  "column-layout": "awsui_column-layout_vvxn7_fj3cv_97",
  "grid": "awsui_grid_vvxn7_fj3cv_132",
  "grid-no-gutters": "awsui_grid-no-gutters_vvxn7_fj3cv_138",
  "grid-variant-text-grid": "awsui_grid-variant-text-grid_vvxn7_fj3cv_142",
  "grid-breakpoint-default": "awsui_grid-breakpoint-default_vvxn7_fj3cv_150",
  "grid-columns-1": "awsui_grid-columns-1_vvxn7_fj3cv_153",
  "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vvxn7_fj3cv_153",
  "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vvxn7_fj3cv_156",
  "grid-columns-2": "awsui_grid-columns-2_vvxn7_fj3cv_159",
  "grid-columns-3": "awsui_grid-columns-3_vvxn7_fj3cv_165",
  "grid-columns-4": "awsui_grid-columns-4_vvxn7_fj3cv_171",
  "grid-vertical-borders": "awsui_grid-vertical-borders_vvxn7_fj3cv_187",
  "grid-horizontal-borders": "awsui_grid-horizontal-borders_vvxn7_fj3cv_220"
};
  