
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_dzn3k_193",
  "arrow-outer": "awsui_arrow-outer_xjuzf_dzn3k_197",
  "arrow-inner": "awsui_arrow-inner_xjuzf_dzn3k_197",
  "refresh": "awsui_refresh_xjuzf_dzn3k_232",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_dzn3k_242",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_dzn3k_242",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_dzn3k_245",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_dzn3k_245",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_dzn3k_248",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_dzn3k_248",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_dzn3k_248",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_dzn3k_248",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_dzn3k_251",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_dzn3k_251",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_dzn3k_251",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_dzn3k_251",
  "body": "awsui_body_xjuzf_dzn3k_351",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_dzn3k_390",
  "has-dismiss": "awsui_has-dismiss_xjuzf_dzn3k_394",
  "dismiss": "awsui_dismiss_xjuzf_dzn3k_399",
  "dismiss-control": "awsui_dismiss-control_xjuzf_dzn3k_407",
  "header-row": "awsui_header-row_xjuzf_dzn3k_411",
  "header": "awsui_header_xjuzf_dzn3k_411",
  "content": "awsui_content_xjuzf_dzn3k_436",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_dzn3k_444",
  "container": "awsui_container_xjuzf_dzn3k_544",
  "container-body": "awsui_container-body_xjuzf_dzn3k_552",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_dzn3k_595",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_dzn3k_600",
  "fixed-width": "awsui_fixed-width_xjuzf_dzn3k_603",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_dzn3k_607",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_dzn3k_614",
  "container-arrow": "awsui_container-arrow_xjuzf_dzn3k_626",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_dzn3k_630",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_dzn3k_630",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_dzn3k_647",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_dzn3k_647",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_dzn3k_664",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_dzn3k_664",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_dzn3k_664",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_dzn3k_664",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_dzn3k_680",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_dzn3k_684",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_dzn3k_688",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_dzn3k_1",
  "root": "awsui_root_xjuzf_dzn3k_832",
  "trigger": "awsui_trigger_xjuzf_dzn3k_868",
  "overflow-ellipsis": "awsui_overflow-ellipsis_xjuzf_dzn3k_875",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_dzn3k_883",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_dzn3k_919"
};
  