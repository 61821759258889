
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_15u39_101",
  "button": "awsui_button_vjswe_15u39_105",
  "variant-normal": "awsui_variant-normal_vjswe_15u39_156",
  "variant-icon": "awsui_variant-icon_vjswe_15u39_199",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_15u39_199",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_15u39_199",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_15u39_220",
  "disabled": "awsui_disabled_vjswe_15u39_241",
  "variant-primary": "awsui_variant-primary_vjswe_15u39_248",
  "variant-link": "awsui_variant-link_vjswe_15u39_340",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_15u39_616",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_15u39_896",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_15u39_993",
  "button-no-text": "awsui_button-no-text_vjswe_15u39_1085",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_15u39_1089",
  "full-width": "awsui_full-width_vjswe_15u39_1092",
  "icon-left": "awsui_icon-left_vjswe_15u39_1111",
  "icon-right": "awsui_icon-right_vjswe_15u39_1116",
  "icon": "awsui_icon_vjswe_15u39_1111",
  "link": "awsui_link_vjswe_15u39_1135"
};
  