
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_lm6vo_w4b3v_102",
  "resize-side": "awsui_resize-side_lm6vo_w4b3v_108",
  "resize-bottom": "awsui_resize-bottom_lm6vo_w4b3v_111",
  "with-motion": "awsui_with-motion_lm6vo_w4b3v_116",
  "root": "awsui_root_lm6vo_w4b3v_131",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_w4b3v_138",
  "layout": "awsui_layout_lm6vo_w4b3v_143",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_w4b3v_149",
  "layout-main": "awsui_layout-main_lm6vo_w4b3v_153",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_w4b3v_159",
  "unfocusable": "awsui_unfocusable_lm6vo_w4b3v_164",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_w4b3v_168",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_w4b3v_173",
  "content-wrapper": "awsui_content-wrapper_lm6vo_w4b3v_177",
  "content-overlapped": "awsui_content-overlapped_lm6vo_w4b3v_181",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_w4b3v_185"
};
  