
    import './styles.scoped.css';
    export default {
  "breadcrumb-group": "awsui_breadcrumb-group_d19fg_1yed3_97",
  "item": "awsui_item_d19fg_1yed3_135",
  "breadcrumb-group-list": "awsui_breadcrumb-group-list_d19fg_1yed3_170",
  "ellipsis": "awsui_ellipsis_d19fg_1yed3_182",
  "icon": "awsui_icon_d19fg_1yed3_192",
  "mobile": "awsui_mobile_d19fg_1yed3_197",
  "mobile-short": "awsui_mobile-short_d19fg_1yed3_210"
};
  