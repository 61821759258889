
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_4c84z_1nv4c_97",
  "variant-secondary": "awsui_variant-secondary_4c84z_1nv4c_166",
  "variant-primary": "awsui_variant-primary_4c84z_1nv4c_201",
  "variant-info": "awsui_variant-info_4c84z_1nv4c_234",
  "variant-value-large": "awsui_variant-value-large_4c84z_1nv4c_269",
  "variant-top-navigation": "awsui_variant-top-navigation_4c84z_1nv4c_301",
  "variant-recovery": "awsui_variant-recovery_4c84z_1nv4c_334",
  "button": "awsui_button_4c84z_1nv4c_367",
  "color-inverted": "awsui_color-inverted_4c84z_1nv4c_402",
  "font-size-body-s": "awsui_font-size-body-s_4c84z_1nv4c_423",
  "font-size-body-m": "awsui_font-size-body-m_4c84z_1nv4c_430",
  "font-size-heading-xs": "awsui_font-size-heading-xs_4c84z_1nv4c_436",
  "font-size-heading-s": "awsui_font-size-heading-s_4c84z_1nv4c_442",
  "font-size-heading-m": "awsui_font-size-heading-m_4c84z_1nv4c_449",
  "font-size-heading-l": "awsui_font-size-heading-l_4c84z_1nv4c_456",
  "font-size-heading-xl": "awsui_font-size-heading-xl_4c84z_1nv4c_463",
  "font-size-display-l": "awsui_font-size-display-l_4c84z_1nv4c_470",
  "font-size-inherit": "awsui_font-size-inherit_4c84z_1nv4c_477",
  "icon-wrapper": "awsui_icon-wrapper_4c84z_1nv4c_485",
  "icon": "awsui_icon_4c84z_1nv4c_485"
};
  