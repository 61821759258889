
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_12i0j_c2clt_101",
  "drawer-content-container": "awsui_drawer-content-container_12i0j_c2clt_114",
  "drawer-close-button": "awsui_drawer-close-button_12i0j_c2clt_122",
  "drawer-content": "awsui_drawer-content_12i0j_c2clt_114",
  "drawer-content-hidden": "awsui_drawer-content-hidden_12i0j_c2clt_131",
  "drawer-slider": "awsui_drawer-slider_12i0j_c2clt_134"
};
  