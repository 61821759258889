
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_1s9d7_97",
  "header": "awsui_header_l0dv0_1s9d7_134",
  "header-link": "awsui_header-link_l0dv0_1s9d7_147",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_1s9d7_157",
  "header-link-text": "awsui_header-link-text_l0dv0_1s9d7_157",
  "header-logo": "awsui_header-logo_l0dv0_1s9d7_162",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_1s9d7_168",
  "list-container": "awsui_list-container_l0dv0_1s9d7_173",
  "list": "awsui_list_l0dv0_1s9d7_173",
  "list-variant-root": "awsui_list-variant-root_l0dv0_1s9d7_185",
  "list-variant-root--last": "awsui_list-variant-root--last_l0dv0_1s9d7_192",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_1s9d7_196",
  "list-item": "awsui_list-item_l0dv0_1s9d7_200",
  "section": "awsui_section_l0dv0_1s9d7_208",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_1s9d7_209",
  "section--no-ident": "awsui_section--no-ident_l0dv0_1s9d7_212",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_1s9d7_213",
  "refresh": "awsui_refresh_l0dv0_1s9d7_221",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_1s9d7_229",
  "section-group": "awsui_section-group_l0dv0_1s9d7_236",
  "section-group-title": "awsui_section-group-title_l0dv0_1s9d7_247",
  "link": "awsui_link_l0dv0_1s9d7_251",
  "link-active": "awsui_link-active_l0dv0_1s9d7_260",
  "info": "awsui_info_l0dv0_1s9d7_297",
  "external-icon": "awsui_external-icon_l0dv0_1s9d7_301",
  "divider": "awsui_divider_l0dv0_1s9d7_305",
  "divider-default": "awsui_divider-default_l0dv0_1s9d7_310",
  "divider-header": "awsui_divider-header_l0dv0_1s9d7_316"
};
  