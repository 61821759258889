
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_obq0g_97",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_obq0g_1",
  "root": "awsui_root_mx3cw_obq0g_123",
  "hidden": "awsui_hidden_mx3cw_obq0g_161",
  "with-dismiss": "awsui_with-dismiss_mx3cw_obq0g_211",
  "with-action": "awsui_with-action_mx3cw_obq0g_211",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_obq0g_217",
  "header": "awsui_header_mx3cw_obq0g_224",
  "action": "awsui_action_mx3cw_obq0g_228",
  "action-slot": "awsui_action-slot_mx3cw_obq0g_233",
  "action-button": "awsui_action-button_mx3cw_obq0g_234",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_obq0g_238",
  "text": "awsui_text_mx3cw_obq0g_267",
  "icon": "awsui_icon_mx3cw_obq0g_274",
  "message": "awsui_message_mx3cw_obq0g_277",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_obq0g_285",
  "icon-size-big": "awsui_icon-size-big_mx3cw_obq0g_288",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_obq0g_291",
  "content": "awsui_content_mx3cw_obq0g_295",
  "dismiss": "awsui_dismiss_mx3cw_obq0g_299",
  "dismiss-button": "awsui_dismiss-button_mx3cw_obq0g_304",
  "type-error": "awsui_type-error_mx3cw_obq0g_308",
  "type-warning": "awsui_type-warning_mx3cw_obq0g_316",
  "type-success": "awsui_type-success_mx3cw_obq0g_324",
  "type-info": "awsui_type-info_mx3cw_obq0g_332"
};
  