
    import './styles.scoped.css';
    export default {
  "navigation": "awsui_navigation_2p2ab_23w7n_97",
  "is-navigation-open": "awsui_is-navigation-open_2p2ab_23w7n_125",
  "animating": "awsui_animating_2p2ab_23w7n_129",
  "openNavigation": "awsui_openNavigation_2p2ab_23w7n_1",
  "animated-content": "awsui_animated-content_2p2ab_23w7n_142",
  "hide-navigation": "awsui_hide-navigation_2p2ab_23w7n_151"
};
  