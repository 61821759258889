
    import './styles.scoped.css';
    export default {
  "button": "awsui_button_m5h9f_vvkqp_97",
  "expanded": "awsui_expanded_m5h9f_vvkqp_152",
  "offset-right-none": "awsui_offset-right-none_m5h9f_vvkqp_163",
  "offset-right-l": "awsui_offset-right-l_m5h9f_vvkqp_166",
  "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_vvkqp_169",
  "text": "awsui_text_m5h9f_vvkqp_194",
  "icon": "awsui_icon_m5h9f_vvkqp_198"
};
  