
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2rhyz_4n5z9_97",
  "input": "awsui_input_2rhyz_4n5z9_101",
  "input-readonly": "awsui_input-readonly_2rhyz_4n5z9_151",
  "input-invalid": "awsui_input-invalid_2rhyz_4n5z9_218",
  "input-has-icon-left": "awsui_input-has-icon-left_2rhyz_4n5z9_229",
  "input-warning": "awsui_input-warning_2rhyz_4n5z9_234",
  "input-type-search": "awsui_input-type-search_2rhyz_4n5z9_250",
  "input-has-icon-right": "awsui_input-has-icon-right_2rhyz_4n5z9_263",
  "input-has-no-border-radius": "awsui_input-has-no-border-radius_2rhyz_4n5z9_266",
  "input-container": "awsui_input-container_2rhyz_4n5z9_273",
  "input-icon-left": "awsui_input-icon-left_2rhyz_4n5z9_278",
  "input-icon-right": "awsui_input-icon-right_2rhyz_4n5z9_285",
  "input-button-right": "awsui_input-button-right_2rhyz_4n5z9_291"
};
  