
    import './styles.scoped.css';
    export default {
  "dialog": "awsui_dialog_1d2i7_xpcku_109",
  "modal-slide-up": "awsui_modal-slide-up_1d2i7_xpcku_1",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1d2i7_xpcku_1",
  "refresh": "awsui_refresh_1d2i7_xpcku_132",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_1d2i7_xpcku_1",
  "root": "awsui_root_1d2i7_xpcku_165",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_1d2i7_xpcku_1",
  "hidden": "awsui_hidden_1d2i7_xpcku_249",
  "focus-lock": "awsui_focus-lock_1d2i7_xpcku_253",
  "small": "awsui_small_1d2i7_xpcku_273",
  "medium": "awsui_medium_1d2i7_xpcku_276",
  "large": "awsui_large_1d2i7_xpcku_279",
  "max": "awsui_max_1d2i7_xpcku_282",
  "breakpoint-xs": "awsui_breakpoint-xs_1d2i7_xpcku_282",
  "container": "awsui_container_1d2i7_xpcku_288",
  "content": "awsui_content_1d2i7_xpcku_332",
  "no-paddings": "awsui_no-paddings_1d2i7_xpcku_337",
  "header": "awsui_header_1d2i7_xpcku_342",
  "header--text": "awsui_header--text_1d2i7_xpcku_354",
  "footer": "awsui_footer_1d2i7_xpcku_358",
  "footer--stuck": "awsui_footer--stuck_1d2i7_xpcku_367",
  "dismiss-control": "awsui_dismiss-control_1d2i7_xpcku_379",
  "modal-open": "awsui_modal-open_1d2i7_xpcku_383"
};
  